import { getLocalStorageCookiesPreferences } from '~/components/cookies/cookiesUtils'

export default function ({ $gtm, $config }) {
  if (process.client) {
    try {
      const cookiesPreferences = getLocalStorageCookiesPreferences()
      window.dataLayer = window.dataLayer || []
      function gtag() {
        /* eslint no-undef: "off" */
        dataLayer.push(arguments)
      }
      if (!cookiesPreferences) {
        gtag('consent', 'default', {
          functionality_storage: 'granted',
          analytics_storage: 'denied',
          performance_storage: 'denied',
          ad_personalization: 'denied',
          ad_user_data: 'denied',
          ad_storage: 'denied',
        })
      } else {
        if (!Array.isArray(cookiesPreferences)) {
          console.log('not array', cookiesPreferences)
        }
        gtag('consent', 'default', {
          ...cookiesPreferences.reduce(
            (previousValue, currentValue) => ({
              ...previousValue,
              [currentValue.tag]: currentValue?.agree ? 'granted' : 'denied',
            }),
            {},
          ),
          ad_storage: cookiesPreferences.find(
            (item) => item.tag === 'ad_personalization',
          )?.agree
            ? 'granted'
            : 'denied',
        })
      }

      gtag('event', 'cookie_consent_update')

      const gtmByDomain = {
        2: 'GTM-N62W54K',
        8: 'GTM-5FH49FM',
        10: 'GTM-NQ8N5MW',
        11: 'GTM-P8JVT8Q',
        13: 'GTM-NG73STS',
        14: 'GTM-58QFD5N',
        15: 'GTM-KHL67ZF',
      }

      $gtm.init(gtmByDomain[$config.domainId])

    } catch (error) {
      console.log('gtm.js plugin error: ' + error)
    }
  }
}
