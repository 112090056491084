//
export const state = () => ({
  countryName: '',
  // bogon: false,
  // city: '',
  // countryCode: '',
  // ip: '',
  // loc: '',
  // region: ''
})

export const mutations = {
  setCountryName(state, value) {
    state.countryName = value
  },
}

export const getters = {
  getCountryName: (state) => {
    return state.countryName
  },
}

export const actions = {
  async initLocation({ commit }) {
    try {
      const sessionStorageAdapter = new SessionStorageAdapter()
      const countryNameFromStorage =
        sessionStorageAdapter.getItem('countryName')

      if (countryNameFromStorage) {
        commit('setCountryName', countryNameFromStorage)
        return
      }

      const REST_API = this.$config.apiUrl.replace('graphql', 'location')
      const response = await fetch(REST_API)

      if (!response.ok) throw new Error('API error')

      const parsedResponse = await response.json()
      const { countryName } = parsedResponse

      if (!countryName) throw new Error('location response invalid data')

      sessionStorageAdapter.setItem('countryName', countryName)
      commit('setCountryName', countryName)
    } catch (ex) {
      console.log(ex)
    }
  },
}

function SessionStorageAdapter() {
  let storage = {
    setItem: () => {},
    getItem: () => '',
  }
  if (window?.sessionStorage) storage = sessionStorage

  this.setItem = function (key, value) {
    storage.setItem(key, value)
  }
  this.getItem = function (key) {
    return storage.getItem(key)
  }
}
